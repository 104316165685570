<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>导航右侧链接</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div></div>
        </div>
        <div class="page_content">
            <el-table :data="tableData" border @row-click="handleRowClick" row-key="id" ref="tab">
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="type" align="center" label="类型">
                    <template slot-scope="{ row }">
                        <span>{{ row.type == "0" ? "普通项" : row.type }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#d66bff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_add" v-if="row.pid == 0">
                            <button @click="openAdd(row)">添加子分类</button>
                        </div>
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete" v-if="row.pid != 0">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹框 -->
        <el-dialog :title="form.id ? '编辑' : '添加'" :visible.sync="show" width="70%" :show-close="false" :before-close="bfc">
            <el-form :model="form" label-width="80px" size="small">
                <template v-if="form.pid != 0">
                    <el-form-item label="标题">
                        <el-input v-model.trim="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="链接">
                        <el-input v-model.trim="form.url"></el-input>
                    </el-form-item>
                    <el-form-item label="图片">
                        <UploadImg :src="form.image" @removeSrc="form.image = ''" ref="image"></UploadImg>
                    </el-form-item>
                </template>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        active-text="显示"
                        inactive-text="隐藏"
                        :inactive-value="0"
                        :active-value="1"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import UploadImg from "../../customs/uploadImg.vue";
import QueryString from "qs";

export default {
    components: { UploadImg },
    data() {
        return {
            tableData: [],
            form: {},
            show: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/hotspot/show");
            return data;
        },
        openAdd(row) {
            this.form = {
                title: "",
                url: "",
                pid: row.id,
                image: "",
                status: 1,
            };
            this.show = true;
        },
        openSet(row) {
            if (row.pid == 0) {
                this.form = {
                    pid: 0,
                    id: row.id,
                    status: row.status,
                };
            } else {
                this.form = { ...row };
            }
            this.show = true;
        },
        // 修改或添加二级
        async yes() {
            let { form } = this;
            if (form.pid == 0) return this.onceUpdate(form);
            form.image = this.$refs.image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.post("/api/hotspot/update", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.put("/api/hotspot/add", QueryString.stringify(form));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.$refs.image.remove();
            this.show = false;
            this.tableData = await this.getData();
        },
        // 修改一级
        async onceUpdate(form) {
            let { data } = await axios.put("/api/hotspot/categoryup", QueryString.stringify(form));
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.show = false;
            this.tableData = await this.getData();
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/hotspot/del", { params: { id } });
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
.sw {
    /deep/.is-active {
        color: #7781f1 !important;
    }
}
</style>
